import React, { useState, useEffect, useMemo, useCallback } from 'react'
import tw, { styled } from 'twin.macro'
import useLocale from '../hooks/useLocale'
import VisuallyHidden from '@reach/visually-hidden'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from './utils/filter-locale'
import Meta from '../components/meta'
import { Helmet } from 'react-helmet'

const windowGlobal = typeof window !== 'undefined' && window

const Section = styled.section`
	${tw`relative overflow-hidden`}
	background: linear-gradient(90deg, rgba(49,55,67,1) 0%, rgba(27,125,154,1) 50%);
`
const Wrapper = styled.div`
	${tw`w-full lg:w-6/12`}
`
const Container = styled.div`
	${tw`relative z-10 pb-8 w-full py-16 xl:py-40`}
`
const Controls = tw.div`pl-4 sm:pl-8 lg:pl-10 xl:pl-6 xxl:pl-8 xxxl:pl-12 flex items-center mb-6 xl:mb-0`
const Nav = tw.nav`relative flex items-center justify-between sm:h-10 lg:justify-start`
const Dots = styled.div`
	${tw`flex flex-row xl:flex-col items-center flex-grow flex-shrink-0 lg:flex-grow-0 text-gray-500 mt-px`}
	button {
		&:first-of-type {
			${tw`mr-2 xl:mr-0 xl:mb-6`}
		}
		${tw`border-t-0 border-l-0 border-b-2 border-r-2 border-blue-600 bg-gray-200 hover:bg-white hover:border-blue-400`}
		${tw`w-4 h-4 sm:w-6 sm:h-6 rounded-full focus:outline-none focus:shadow-outline`}
		&:hover {
			${tw`border-t-2 border-l-2 border-b-2 border-r-2`}
		}
		&.isActive {
			${tw`bg-gray-200`}
			box-shadow: #58c8df 0 0 4px;
			${tw`border-t-2 border-l-2 border-b-2 border-r-2 border-blue-500`}
		}
	}
`
const TextWrapper = tw.div`w-full flex flex-col xl:flex-row`
const Text = styled.div`
	${tw`text-center lg:text-left relative lg:z-20 px-4 sm:px-8 lg:px-10 xl:pr-4 xxxl:pr-12 xl:pl-6 xxl:pl-8 xxxl:pl-12`}
	h2 {
		${tw`text-3xl md:text-5xl xxxl:text-6xl tracking-tight leading-10 font-bold font-display text-white sm:leading-none xxl:leading-tight xxxl:leading-none`}
		span {
			${tw`text-white`}
		}
	}
	p {
		${tw`mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0`}
	}
	.group {
		${tw`mt-5 mx-auto flex md:block flex-col`}
		button {
			${tw`transition duration-300 ease-in-out w-56 md:w-auto mx-auto`}
			a {
				${tw`w-full flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md focus:outline-none transition duration-300 ease-in-out xl:py-3 xl:text-lg xl:px-8`}
				&:focus {
					box-shadow: 0 0 0 3px rgba(65, 201, 225, 0.5);
				}
			}
			&.solid {
				${tw`rounded-md shadow hover:shadow-none`}
				a {
					${tw`bg-yellow-500 hover:bg-yellow-400 uppercase`}
				}
			}
			&.outline {
				${tw`mt-3 ml-auto md:ml-3 md:mt-0 rounded-md shadow-none`}
				a {
					${tw`text-white uppercase bg-transparent hover:text-current hover:bg-blue-50 border-2 border-blue-200 hover:border-blue-50`}
				}
			}
		}
	}
`
const Image = styled.div`
	${tw`lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2`}
	img {
		${tw`h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full`}
	}
`

const Hero2 = ({ locale }) => {
	const { firstBanner, secondBanner } = useStaticQuery(graphql`
		query homeHeroBannersQuery {
			site {
				siteMetadata {
					title
					description
					siteUrl
				}
			}
			firstBanner: allContentfulBanner(
				filter: { contentful_id: { eq: "75VuNhVVpVH5U7Jiv2KM3G" } }
			) {
				edges {
					node {
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						node_locale
						hook
						description {
							json
						}
						backgroundImage {
							description
							title
							fluid(
								maxWidth: 700
								quality: 80
								cropFocus: LEFT
								resizingBehavior: FILL
							) {
								...GatsbyContentfulFluid_withWebp
								src
								srcSet
							}
						}
					}
				}
			}

			secondBanner: allContentfulBanner(
				filter: { contentful_id: { eq: "7IXompgH7PGnBQesV5VGG1" } }
			) {
				edges {
					node {
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						node_locale
						hook
						description {
							json
						}
						backgroundImage {
							description
							title
							fluid(
								maxWidth: 700
								quality: 80
								cropFocus: TOP
								resizingBehavior: FILL
							) {
								...GatsbyContentfulFluid_withWebp_noBase64
								src
								srcSet
							}
						}
					}
				}
			}
		}
	`)
	const [{ node: first }] = filterLocale(firstBanner.edges, locale)
	const [{ node: second }] = filterLocale(secondBanner.edges, locale)

	const bannerArray = [{ ...first }, { ...second }]
	const [state, setState] = useState({
		bannerArray: [{ ...first }, { ...second }],
		activeBanner: bannerArray[0],
		bannerIndex: 0,
		pathname: '',
	})

	const dotClickHandler = useCallback(
		index => {
			setState(prevState => ({
				...prevState,
				activeBanner: bannerArray[index],
				bannerIndex: index,
			}))
		},
		[bannerArray]
	)

	useEffect(() => {
		const intervalId = setInterval(() => {
			setState(prevState => ({
				...prevState,
				bannerIndex: (prevState.bannerIndex + 1) % bannerArray.length,
				activeBanner:
					bannerArray[(prevState.bannerIndex + 1) % bannerArray.length],
			}))
		}, 10000)

		return () => {
			clearInterval(intervalId)
		}
	}, [bannerArray])

	const options = useMemo(
		() => ({
			renderNode: {
				[BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
			},
		}),
		[]
	)

	useEffect(() => {
		if (windowGlobal) {
			const path = windowGlobal.location.pathname
			setState(prevState => ({ ...prevState, pathname: path }))
		}
	}, [])

	const { globalLocale } = useLocale(state.pathname)

	const checkUrl =
		globalLocale !== 'en-AU'
			? state.activeBanner.secondActionUrl.includes(globalLocale)
			: null

	// Extract banner text for structured data and SEO
	const bannerTitle =
		state.activeBanner.hook || 'Robotic Pool Cleaners & Vacuums'
	const bannerDescription =
		state.activeBanner.description?.json?.content?.[0]?.content?.[0]?.value ||
		'Discover our robotic pool cleaners and vacuums for effortless pool maintenance.'

	// Extract image data for structured data
	const imageUrl = state.activeBanner.backgroundImage?.fluid?.src || ''

	return (
		<>
			<Helmet>
				<title>Robotic Pool Cleaners & Vacuums | Premium Pool Solutions</title>
				<meta name="description" content={bannerDescription} />
				<meta property="og:title" content={bannerTitle.replace(',', '')} />
				<meta property="og:description" content={bannerDescription} />
				<meta property="og:image" content={imageUrl} />
				<meta property="og:type" content="product" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={bannerTitle.replace(',', '')} />
				<meta name="twitter:description" content={bannerDescription} />
				<meta name="twitter:image" content={imageUrl} />
			</Helmet>

			<Meta title="Robotic Pool Cleaners & Vacuums">
				<meta name="description" content={bannerDescription} />
				<meta
					name="keywords"
					content="robotic pool cleaners, pool vacuums, automatic pool cleaners, pool maintenance, swimming pool equipment"
				/>
			</Meta>

			<Section>
				<Wrapper>
					<Container>
						<TextWrapper>
							<Controls>
								<Nav role="navigation" aria-label="Banner navigation">
									<Dots role="menu">
										{bannerArray.map((banner, index) => {
											return (
												<button
													className={
														state.bannerIndex === index ? 'isActive' : ''
													}
													key={index}
													aria-label={`View banner ${index + 1}: ${
														banner.hook.split(',')[0]
													}`}
													onClick={() => dotClickHandler(index)}
													aria-current={
														state.bannerIndex === index ? 'true' : 'false'
													}
												>
													<VisuallyHidden>{index + 1}</VisuallyHidden>
												</button>
											)
										})}
									</Dots>
								</Nav>
							</Controls>
							<Text>
								<h2>
									{state.activeBanner.hook.split(',')[0]}
									<br />
									<span>{state.activeBanner.hook.split(',')[1]}</span>
								</h2>
								{documentToReactComponents(
									state.activeBanner.description.json,
									options
								)}
								<div className="group">
									<button className="solid">
										<Link
											className="solid"
											to={'/' + state.activeBanner.mainActionUrl}
											aria-label={state.activeBanner.mainActionText}
										>
											{state.activeBanner.mainActionText}
										</Link>
									</button>
									{(checkUrl === null || checkUrl !== false) && (
										<button className="outline">
											<Link
												to={'/' + state.activeBanner.secondActionUrl}
												aria-label={state.activeBanner.secondActionText}
											>
												{state.activeBanner.secondActionText}
											</Link>
										</button>
									)}
								</div>
							</Text>
						</TextWrapper>
					</Container>
				</Wrapper>
				<Image>
					<Img
						css={tw`h-full`}
						loading="eager"
						fetchPriority="high"
						alt={
							state.activeBanner.backgroundImage.description ||
							'Robotic Pool Cleaner'
						}
						title={
							state.activeBanner.backgroundImage.title ||
							'Robotic Pool Cleaning Solution'
						}
						{...state.activeBanner.backgroundImage}
					/>
				</Image>
			</Section>
		</>
	)
}

export default Hero2
